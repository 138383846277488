import { promiseScript } from '../utils'

const plausible = ({ env, logger, config }) => {
  window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }

  if (config.enabled) {
    promiseScript('plausible', `https://${config.endpoint}/js/script.js`, {
      'data-domain': config.domain,
      'data-api': `https://${config.endpoint}/api/event`,
    })
      .then(() => logger.info('initialized Plausible'))
      .catch((err) => logger.warn('failed to load Plausible', err));

    return {
      event(opts) {
        const { category, action, label, payload } = opts || {};

        if (!category || !action) {
          logger.warn('cannot report event to Plausible; must define category and action');
          return;
        }

        const title = `${category}/${action}`;
        const props = {
          label,
          ...(payload || {}),
        };

        window.plausible(title, { props });
      },
    };
  }
};

export default plausible;

